/* eslint-disable @typescript-eslint/no-explicit-any */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useRef, useState } from 'react'

import Icon from '@/components/Icon'

import useResize from '@/hooks/useResize'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

interface Props {
  items?: Array<{
    _key?: string
    _id?: string
    title: string
    href?: string
    onClick?: () => void
    icon?: string
    selected?: boolean
  }>
  active?: string | null
  inline?: boolean
  inlineStyle?: boolean
  hideBack?: boolean
  children?: ReactNode
  // TODO: Replace all back stuff!:
  back?: {
    title: string
    href: string
  }
}

export default function NavList({
  items = [],
  inline,
  inlineStyle,
  active,
  hideBack,
  back,
  children,
}: Props) {
  const router = useRouter()
  const listRef = useRef<HTMLDivElement>(null)
  const [overflow, setHasOverflow] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const hasItems = (items || []).length > 0

  const handleClick = (id: string) => (event: any) => {
    event.preventDefault()
    setExpanded(false)

    const goToElement = document.getElementById(id)

    if (!goToElement) {
      console.warn(`Tried scrolling to #${id}, but no such id exist`)
      return
    }

    const scrollToPostion = goToElement.offsetHeight >= window.innerHeight - 50 ? 'start' : 'center'

    goToElement.scrollIntoView({ behavior: 'smooth', block: scrollToPostion })
    // const to = getPosition(goToElement) - window.innerHeight / 2
    // scrollTo(to, 400)
    event.target.blur()
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setExpanded(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => router.events.off('routeChangeStart', handleRouteChange)
  }, [router.events])

  useEffect(() => {
    if (!active || !listRef.current || !overflow) {
      return
    }

    const top = listRef.current.offsetTop
    const scrollPos = listRef.current.scrollTop
    const height = listRef.current.offsetHeight
    const activeElement = document.getElementById(`nav-${active}`)

    if (!activeElement) {
      return
    }

    const elementTop = activeElement.offsetTop - top
    const elementBottom = elementTop + activeElement.offsetHeight

    if (elementTop < scrollPos) {
      listRef.current.scrollTo({ top: elementTop - 4, behavior: 'smooth' })
    } else if (elementBottom > scrollPos + height) {
      listRef.current.scrollTo({ top: elementBottom + 4, behavior: 'smooth' })
    }
  }, [active, overflow])

  useResize(() => {
    if (listRef.current) {
      setHasOverflow(listRef.current.scrollHeight > listRef.current.clientHeight)
    }
  }, [hasItems])

  const toggle = () => {
    setExpanded((expanded) => !expanded)
  }

  const activeItem =
    active && hasItems ? items.find(({ _key, _id }) => active === (_key || _id)) : null

  return (
    <nav {...bem('', { 'hidden-mobile': !inline && !inlineStyle })}>
      <div {...bem('content', { expandable: hasItems })}>
        {!hideBack && (
          <Link {...bem('back')} href={back?.href || '/'}>
            <span {...bem('back-circle')}>
              <Icon icon="chevron" direction="left" />
            </span>
            <span {...bem('back-label')}>{back?.title || 'Tilbake'}</span>
          </Link>
        )}

        {hasItems && (
          <button
            {...bem('toggle', { inline: inlineStyle || inline })}
            onClick={toggle}
            aria-label="Vis liste"
          >
            {(inlineStyle || inline) && (
              <span {...bem('active-label')}>{activeItem?.title || 'Innhold'}</span>
            )}
            <Icon icon={inlineStyle || inline ? 'list' : 'hamburger'} />
          </button>
        )}

        {hasItems && (
          <div {...bem('list', { expanded, overflow })} ref={listRef}>
            {items.map(({ _key = '', _id, title, href, icon, selected, onClick }) => {
              const Node = inline ? 'a' : Link
              const props = inline ? { onClick: handleClick(_key) } : { onClick }

              if (href) {
                return (
                  <Node
                    {...props}
                    {...bem('item', {
                      icon,
                      inline: inlineStyle || inline,
                      current: !inline && active === (_key || _id),
                      active: (inline && active === (_key || _id)) || selected,
                    })}
                    id={`nav-${_key || _id}`}
                    key={_key || _id}
                    href={inline ? `#${_key}` : href ?? ''}
                  >
                    {title}
                    {icon && <Icon icon={icon} {...bem('icon')} />}
                  </Node>
                )
              }

              return (
                <button
                  {...props}
                  {...bem('item', {
                    icon,
                    inline: inlineStyle || inline,
                    current: !inline && active === (_key || _id),
                    active: (inline && active === (_key || _id)) || selected,
                  })}
                  id={`nav-${_key || _id}`}
                  key={_key || _id}
                >
                  {title}
                  {icon && <Icon icon={icon} {...bem('icon')} />}
                </button>
              )
            })}
          </div>
        )}
        {children}
      </div>
    </nav>
  )
}
