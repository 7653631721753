import useSWR, { SWRHook } from 'swr'
import { useCallback, useEffect, useRef } from 'react'
import { client, getIndexName } from '../lib/algolia'

type Filters = {
  duration?: { from?: number; to?: number }
  subjects?: string
  types?: string
  grades?: { grunnskole?: boolean; vgs?: boolean }
  topicSlug?: string
  'topic.slug'?: string
  topic?: string
}

type DurationFilters = string[]
type SubjectFilters = string[]
type TopicFilters = string[]
type Filter = string[]
type TypeFilters = string[]
type GradeFilters = string[]

function buildFilterString(filters: Filters) {
  const durationFilters: DurationFilters = []
  if (filters.duration) {
    if (typeof filters.duration.from === 'number' && !isNaN(filters.duration.from)) {
      durationFilters.push(`duration.from>=${filters.duration.from}`)
    }
    if (typeof filters.duration.to === 'number' && !isNaN(filters.duration.to)) {
      durationFilters.push(`duration.to>=${filters.duration.to}`)
    }
  }
  const durationFilter = durationFilters.join(' AND ')
  const subjectFilters: SubjectFilters = []
  if (typeof filters.subjects === 'string') {
    const arr = filters.subjects.split(';')
    for (const filterSubject of arr) {
      subjectFilters.push(`subjects:${filterSubject}`)
    }
  }
  const subjectFilter = subjectFilters.join(' OR ')

  const topicFilters: TopicFilters = []
  if (typeof filters.topic === 'string') {
    const arr = filters.topic.split(';')
    for (const filterTopic of arr) {
      topicFilters.push(`topic.slug:${filterTopic}`)
    }
  }

  const topicFilter = topicFilters.join(' OR ')

  const typeFilters: TypeFilters = []
  if (typeof filters.types === 'string') {
    const arr = filters.types.split(';')
    for (const filterType of arr) {
      typeFilters.push(`type:${filterType}`)
    }
  }
  const typeFilter = typeFilters.join(' OR ')

  const gradeFilters: GradeFilters = []
  if (filters.grades) {
    if (filters.grades.grunnskole === true) {
      gradeFilters.push(`usedInGrades.grunnskole:true`)
    }
    if (filters.grades.vgs === true) {
      gradeFilters.push(`usedInGrades.vgs:true`)
    }
  }
  const gradeFilter = gradeFilters.join(' OR ')

  const filter: Filter = []
  if (durationFilter) {
    filter.push(`(${durationFilter})`)
  }
  if (subjectFilter) {
    filter.push(`(${subjectFilter})`)
  }
  if (topicFilter) {
    filter.push(`(${topicFilter})`)
  }
  if (typeFilter) {
    filter.push(`(${typeFilter})`)
  }
  if (gradeFilter) {
    filter.push(`(${gradeFilter})`)
  }
  return filter.join(' AND ')
}

// This is a SWR middleware for keeping the data even if key changes.
// ref https://swr.vercel.app/docs/middleware#keep-previous-result
function laggy(useSWRNext: SWRHook) {
  return (key, fetcher, config) => {
    // Use a ref to store previous returned data.
    const laggyDataRef = useRef()

    // Actual SWR hook.
    const swr = useSWRNext(key, fetcher, config)

    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data
      }
    }, [swr.data])

    // Expose a method to clear the laggy data, if any.
    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined
    }, [])

    // Fallback to previous data if the current data is undefined.
    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data

    // Is it showing previous data?
    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined

    // Also add a `isLagging` field to SWR.
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    })
  }
}

function fetchData(query: string, filters: string, supportedTypes: string[]) {
  if (!query) {
    return
  }

  const baseFilter = `(${supportedTypes
    .map((supportedType) => `type:${supportedType}`)
    .join(' OR ')})`

  return client.multipleQueries<{
    type?: string
    subjects?: string[]
    topic: {
      _id: string
      slug: string
      title: string
    }
    'topic.slug': string[]
    duration?: { _type: 'duration'; from?: number; to?: number }
    file?: string
    'usedInGrades.grunnskole'?: boolean
    'usedInGrades.vgs'?: boolean
    types?: string[]
    title?: string
    slug?: string
    _id?: string
  }>([
    // the first query is to get the facets that is not filtered
    {
      indexName: getIndexName(),
      query,
      params: {
        hitsPerPage: 0,
        attributesToRetrieve: [],
        facets: ['*'],
        filters: baseFilter,
      },
    },
    // the second query returns the actual hits, with optional filters
    {
      indexName: getIndexName(),
      query,
      params: {
        hitsPerPage: 200,
        attributesToRetrieve: [
          'objectID',
          'type',
          'slug',
          'title',
          'types',
          'duration',
          'purpose',
          'preamble',
          'text',
          'body',
          'image',
          'topic',
          'topicSlug',
          'topic.slug',
          'subjects',
          'publishedAt',
          'file',
          'grade',
          'youtubeId',
          'video',
          'caption',
          'typeTag',
        ],
        filters: [filters, baseFilter].filter(Boolean).join(' AND '),
        facets: ['*'],
      },
    },
  ])
}

export default function useSearch(
  query: string,
  {
    filtersInput,
    supportedTypes,
  }: {
    filtersInput: Filters
    supportedTypes: string[]
  },
) {
  const filters = buildFilterString(filtersInput)
  const hasSearched = query?.length !== 0 || filters?.length !== 0
  const queryString = query.length > 0 ? query : '*'
  const {
    data,
    isValidating: loading,
    error,
  } = useSWR(
    ['useSearch', queryString, filters, supportedTypes],
    ([, queryString, filters, supportedTypes]) => fetchData(queryString, filters, supportedTypes),
    { use: [laggy] },
  )
  return {
    loading,
    error,
    data:
      hasSearched && data
        ? {
            nbHits: 'facets' in data.results[0] ? data.results[0].nbHits : undefined,
            facets: 'facets' in data.results[0] ? data.results[0].facets : undefined,
            filteredFacets: 'facets' in data.results[1] ? data.results[1].facets : undefined,
            hits: 'facets' in data.results[1] ? data.results[1].hits : undefined,
          }
        : undefined,
  }
}
