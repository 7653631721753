import algoliasearch from 'algoliasearch'

export const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '2VJD1TGA0Y',
  process.env.ALGOLIA_WRITE_TOKEN || '82c2dc8a846a61077f78cc363ff836b6',
)

export const getIndexName = () => {
  if (process.env.NEXT_PUBLIC_ALGOLIA_INDEX) {
    return process.env.NEXT_PUBLIC_ALGOLIA_INDEX
  }

  if (typeof document !== 'undefined' && document.location.hostname === 'tenk.faktisk.no') {
    return 'sanity'
  }

  return 'sanity_develop'
}
